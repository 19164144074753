import userApi from './user.js'
import projectApi from './project.js'
import hospitalApi from './hospital.js'
import templetApi from './templet.js'
import patApi from './pat.js'
import fileApi from './file.js'
import logApi from './log.js'
import dictApi from './dict.js'
import questionApi from './question.js'
import goalSettingApi from './goalSetting.js'
import lifetimeVisitApi from './lifetimeVisit.js'
import exportApi from './export.js'
import appUpdateApi from './appUpdate.js'
export default{
    userApi,
    projectApi,
    hospitalApi,
    templetApi,
    patApi,
    fileApi,
    logApi,
    dictApi,
    questionApi,
    goalSettingApi,
    lifetimeVisitApi,
    exportApi,
    appUpdateApi
}