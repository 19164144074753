import axios from '@/utils/request.js'

const templetApi = {
    getAll() {
        return axios.get("/templet/GetAll");
    },
    getTempletByCode(code) {
        return axios.post('/templet/GetTempletByCode?templetCode=' + code);
    },
    add(templet) {
        return axios.post('/templet/add', templet);
    },
    edit(templet) {
        return axios.put('/templet/edit', templet);
    },
    getProjectVsTemplet(projectId) {
        return axios.get('/templet/GetProjectVsTemplet?projectId=' + projectId);
    },
    addProVsTemp(pro) {
        return axios.post('/ProjectVsTemplet/Add', pro)
    },
    deleteProVsTemp(id) {
        return axios.delete('/ProjectVsTemplet/Delete?id=' + id);
    },
    addLifetimeVsTemplet(lifetime) {
        return axios.post('/LifetimeVsTemplet/Add', lifetime);
    },
    getLifetimeVsTemplet() {
        return axios.get('/LifetimeVsTemplet/GetAll');
    },
    deleteLifeTimeVsTemplet(id) {
        return axios.delete('/LifetimeVsTemplet/Delete?id=' + id);
    },
    addEle(eles) {
        return axios.post('/Element/AddElements', eles);
    },
    sortUp(id) {
        return axios.post('/ProjectVsTemplet/SortUp?id=' + id);
    },
    sortDown(id) {
        return axios.post('/ProjectVsTemplet/SortDown?id=' + id);
    }

}

export default templetApi