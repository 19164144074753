import axios from '@/utils/request.js'

const hospitalApi = {
    getAll() {
        return axios.get("/hospital/GetAll");
    },
    getHospitalByNo(hospitalCode) {
        return axios.post('/hospital/GetHospitalByNo?hospitalNo=' + hospitalCode);
    },
    add(hospital) {
        return axios.post('/hospital/add', hospital);
    },
    edit(hospital) {
        return axios.put('/hospital/edit', hospital);
    },
    getHospitalByUser(userId) {
        return axios.get('/hospital/GetHospitalByUser?userId=' + userId);
    }
}

export default hospitalApi