import axios from '@/utils/request.js'

const fileApi = {
    getFilesByScreenNoAndProject(projectId, screenNo) {
        return axios.get(`/CrfFile/GetFilesByScreenNoAndProject?screenNo=${screenNo}&projectId=${projectId}`);
    },
    getFilesByLifetime(projectId, screenNo,lifetime){
        return axios.get(`/CrfFile/GetFilesByLifetime?screenNo=${screenNo}&projectId=${projectId}&lifetime=${lifetime}`);
    },
    getFilesByFileUniqueId(fileUniqueId) {
        return axios.get(`/CrfFile/GetFilesByFileUniqueId?fileUniqueId=${fileUniqueId}`);
    },
    add(file) {
        return axios.post('/CrfFile/AddFile', file);
    },
    updateFileContent(file) {
        return axios.put("/CrfFile/UpdateFileContent", file);
    },
    save(fileUniqueId, fileContent, fileFlag) {
        return axios.post('/CrfFile/Save', {
            fileUniqueId: fileUniqueId,
            fileContent: fileContent,
            fileFlag: fileFlag
        });
    },
    getFileBaseInfo(fileUniqueId) {
        return axios.get('/CrfFile/GetFileBaseInfo?fileUniqueId=' + fileUniqueId);
    },
    sign(file) {
        return axios.post('/CrfFile/Sign', file);
    },
    AuditSign(file) {
        return axios.post('/CrfFile/AuditSign', file);
    },
    upload(upload) {
        return axios.fileUpLoad("Upload/Upload", upload);
    },
    getUpload(screenNo, projectId) {
        return axios.get(`/Upload/GetUploads?screenNo=${screenNo}&projectId=${projectId}`);
    },
    addDrugRecord(drug) {
        return axios.post('/DurgRecord/Add', drug);
    },
    saveAe(data, fileInfo) {
        var aeRecord = {
            aeAction: data.aeAction,
            aeName: data.aeName,
            aeOtherAction: data.aeOtherAction,
            drugAction: data.drugAction,
            saeAction: data.saeAction,
            creator: fileInfo.creator,
            drugRelation: data.drugRelation,
            endDate: data.endDate,
            endTime: data.endTime,
            fileUniqueId: fileInfo.fileUniqueId,
            isQuit: data.isQuit,
            isSae: data.isSae,
            saeCondition: data.saeCondition,
            saeOtherCondition: data.saeOtherCondition,
            outcomes: data.outcomes,
            projectId: fileInfo.projectId,
            saeRemark: data.saeRemark,
            screenNo: fileInfo.screenNo,
            severity: data.severity,
            startDate: data.startDate,
            startTime: data.startTime,
            aeNo: data.aeNo,
        };
        return axios.post('/AeRecord/Add', aeRecord);
    },
    saveAeRecord(data) {
        return axios.post('/AeRecord/AddSxnAeRecords', data);
    },
    delSxnAeRecord(fileUniqueId) {
        return axios.post('/AeRecord/DelSxnAeRecord?fileUniqueId=' + fileUniqueId);
    },
    getSxnAeRecord(projectId) {
        return axios.get('/AeRecord/GetSxnAeRecord?projectId=' + projectId);
    }
}

export default fileApi