import { createStore } from 'vuex'

export default createStore({
  state: {
    uid: "",
    //存档当前用户信息
    currentUser: {
      loginName: '',
      name: '',
      sex: -1,
      phoneNumber: '',
      email: '',
      roleId: '',
      roleName: '',
      hospitalNo: '',
      hospitalName: ''
    },
    isAuthentication: false,
    //存放当前用户选择的项目信息
    currentProject: {
      projectId: "",
      projectName: "",
      hospitals: [],
      status: -1,
      notClear: 0,
      notClose: 0
    },
    //存放医院字典数据
    hospitals: [],
    //项目界面选择的医院
    hospitalFilter: "",
    currentForm: {
      fileUniqueId: '',
      fileFlag: ''
    },
    signImageUrl: 'http://zcedc.com/signs/',
    uploadImageUrl: 'http://zcedc.com',
    innerHeight: window.innerHeight
  },
  getters: {
  },
  mutations: {
    changeAuthentication(state, _isAuthentication) {
      this.state.isAuthentication = _isAuthentication;
    },
    changeUser(state, _user) {
      this.state.currentUser = _user;
    },
    changeProject(state, _project) {
      this.state.currentProject = _project;
    },
    changeHospital(state, _hospital) {
      this.state.hospitals = _hospital;
    },
    changeHospitalFilter(state, _hospitalFilter) {
      this.state.hospitalFilter = _hospitalFilter
    },
    clearCurrentProject() {
      this.state.currentProject = {
        projectId: "",
        projectName: "",
        hospitals: []
      };
    },
    changeUid(state, _uid) {
      this.state.uid = _uid;
    },
    changeCurrentForm(state, _form) {
      this.state.currentForm = _form;
    },
    changeSignImageUrl(state, _url) {
      this.state.signImageUrl = _url;
    },
    changeUploadImageUrl(state, _url) {
      this.state.uploadImageUrl = _url;
    },
    heightChanged(state,_height){
      this.state.innerHeight = _height;
    }

  },
  actions: {
  },
  modules: {
  }
})
