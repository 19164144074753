<template>
  <div class="login-wrapper" ref="wrapper">
    <div class="wrapper">
      <div class="left">
        <img src="../assets/img/login-box-bg-9027741f.svg" alt="" class="bg-img">
      </div>
      <div class="login-container" v-if="show">
        <Transition>
          <el-card class="box-card inner">
            <template #header>
              <div class="card-header">
                <h3>登 录</h3>
              </div>
            </template>
            <el-form :model="user" :rules="rules" ref="loginForm" size="large">
              <el-form-item prop="loginName">
                <el-input v-model="user.loginName" placeholder="登录用户名" prefix-icon="User" />
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="user.password" placeholder="登录密码" type="password" show-password prefix-icon="Lock" />
              </el-form-item>
              <el-form-item>
                <el-button @click="submit" type="primary" style="width: 100%;" :loading="loading"
                  @keydown.enter="keyDown">登录</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'

export default {
  data() {
    return {
      user: {
        loginName: "",
        password: "",
      },
      loading: false,
      rules: {
        loginName: [{ required: true, message: '用户名不可为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      },
      show: false
    };
  },
  methods: {
    submit() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.login();
        }
      });

    },
    async login() {
      if (this.user.loginName != null && this.user.loginName != "") {

        this.loading = true;
        var result = await this.$api.userApi.login(this.user.loginName, this.user.password);
        this.loading = false;

        if (result == null || result.status === 0) {
          ElMessage.error(result.message);
          return;
        }

        localStorage.setItem("token", result.data);
        
        this.$store.commit('changeAuthentication', true);
        localStorage.setItem('uid', this.user.loginName);
        this.$store.commit('changeUid', this.user.loginName);
        localStorage.removeItem('pid');
        localStorage.removeItem('url');
        this.$route.params.id = "";

        this.$router.push('/');
      }
    },
    keyDown(e) {
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.submit();
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown)
    this.show = true;
  },
  unmounted() {
    window.removeEventListener('keydown', this.keyDown, false)
  }
};
</script>

<style scoped lang="scss">
.login-wrapper {
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .left {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.login-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -48%;
  background-image: url('../assets/img/login-bg-b9f5c736.svg');
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: auto 100%;
  z-index: -1;
}

.login-container {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .el-card {
    width: 400px;

  }
}

.bg-img {
  width: 400px;
}

.v-enter-active,
.v-leave-active {
  transition: all .8s ease;
}

.v-enter,
.v-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
</style>