<template>
  <div class="common-layout">
    <el-container :class="pdfView ? 'pdf' : ''" >
      <el-header v-if="isAuthentication" v-show="!pdfView">
        <HeaderCom />
      </el-header>
      <el-main><router-view /></el-main>
      <el-footer v-if="showFooter" v-show="!pdfView">
        <el-link type="info" href="https://beian.miit.gov.cn" target="_blank">京ICP备2023020459号</el-link>
        <el-text class="mx-1" type="info">&nbsp;&nbsp;&nbsp;&nbsp;</el-text>
        <el-link class="mx-1" type="info">v1.3.6</el-link>
      </el-footer>
    </el-container>
  </div>
</template>

<script>

import { ElMessageBox } from 'element-plus'
import HeaderCom from '@/components/HeaderCom.vue'

export default {
  name: 'HomeView',
  data() {
    return {
      showDialog: false,
    }
  },
  beforeCreate() {
    if (this.$route.name != "pdf") {
      let token = localStorage.getItem('token');
      if (token != null && token != '') {
        this.$store.commit('changeAuthentication', true);
      }
    }

  },
  async created() {
    if (this.$route.name != "pdf") {
      await this.init();
    }
  },
  components: {
    HeaderCom
  },
  computed: {
    isAuthentication() {
      if (this.$route.name == "pdf") return false;
      return this.$store.state.isAuthentication;
    },
    showFooter() {
      if (this.$route.name == "pdf") return false;
      return true;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    currentProject() {
      return this.$store.state.currentProject;
    },
    uid() {
      return this.$store.state.uid;
    },
    pdfView(){
      if (this.$route.fullPath.startsWith("/pdf")) return true;
      return false;
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$store.commit('heightChanged', window.innerHeight)
      })()
    }
  },
  methods: {
    async init() {//初始化方法
      if (this.$route.name == "pdf") return;
      let uid = localStorage.getItem('uid');
      if (uid != undefined) {
        var result = await this.$api.userApi.getUser(uid)
        if (result.status === 1) {
          this.$store.commit('changeUser', result.data);
        } else {
          ElMessageBox.alert('身份验证失败，请重新登录！', '系统错误', {
            // if you want to disable its autofocus
            // autofocus: false,
            confirmButtonText: '确定',
            callback: () => {
              localStorage.removeItem("token");
              location.href = "/login";
            },
          })
        }
        //加载医院字典
        var hosResult = await this.$api.hospitalApi.getAll();
        if (hosResult.status === 1) {
          this.$store.commit('changeHospital', hosResult.data);
        }

        //加载签名图片地址
        var signImage = await this.$api.goalSettingApi.getValue("SignImageBaseUrl");
        if (signImage.status === 1) {
          this.$store.commit('changeSignImageUrl', signImage.data);
        }

        let uploadImage = await this.$api.goalSettingApi.getValue("UploadImageBaseUrl");
        if (uploadImage.status === 1) {
          this.$store.commit('changeUploadImageUrl', uploadImage.data);
        }
      }
    }
  },
  watch: {
    'currentUser.loginName'() {
      this.init();
    },
    uid() {
      this.init();
    }
  },
}
</script>

<style lang="scss">
.el-header {
  padding: 0;
  box-shadow: inset 0 -1px 0 0 rgba(101, 109, 119, .16);
  background-color: #fff;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-container {
  position: fixed;
  /* 页面全屏 */
  width: 100%;
  height: 100%;
  left: 0;
  overflow: hidden;
  background-color: #f3f3f3;
}

.pdf.el-container{
  position: static;
  height: auto;
  overflow: auto;

}

.el-main {
  padding: 0;
}

.el-footer {
  height: 20px;

  .el-timeline-item__timestamp {
    text-align: left;
  }
}
</style>
