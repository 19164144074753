import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'

const routes = [
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/TestView.vue')
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/print/:id',
    name: 'print',
    component: () => import('../views/PrintView.vue')
  },
  {
    path: '/pat',
    name: 'pat',
    component: () => import('../views/PatView.vue')
  },
  {
    path: '/system/',
    name: 'system',
    component: () => import('../views/SystemView.vue'),
    children: [
      {
        path: 'user',
        component: () => import('../components/System/UserManager.vue')
      },
      {
        path: 'crf',
        component: () => import('../components/System/CrfManager.vue')
      },
      {
        path: 'hospital',
        component: () => import('../components/System/HosManager.vue')
      },
      {
        path: 'templet',
        component: () => import('../components/System/TempletManager.vue')
      },
      {
        path: 'project',
        component: () => import('../components/System/ProjectManager.vue')
      },
      {
        path: 'lifetime',
        component: () => import('../components/System/LifeTime.vue')
      },
      {
        path: 'upgrade',
        component: () => import('../components/System/AppUpdate.vue')
      }
    ]
  },
  {
    path: '/project/:id',
    name: 'project',
    component: () => import('../views/ProjectView.vue'),
    children: [
      {
        path: 'pat',
        component: () => import('@/components/Project/PatManage.vue'),
      },
      {
        path: 'overview',
        component: () => import('@/components/Project/OverView.vue')
      },
      {
        path: 'formoverview',
        component: () => import('@/components/Project/PatOverview.vue')
      },
      {
        path: 'setting',
        component: () => import('@/components/Project/CompSetting.vue')
      },
      {
        path: 'export',
        component: () => import('@/components/Project/DataExport.vue')
      },
      {
        path: 'question',
        component: () => import('@/components/Project/QuestionManage.vue')
      },
      {
        path: 'adv',
        component: () => import('@/components/Project/AdverseEvents.vue')
      }
    ]
  },
  {
    path: '/project/:id/pat/:screenNo',
    name: 'pat',
    component: () => import('../views/PatView.vue')
  },
  {
    path: '/pdf/',
    name: 'pdf',
    component: () => import('../views/PdfView.vue'),
    children: [
      {
        path: 'form/:id',
        component: () => import('../components/Export/FormExport.vue')
      },
      {
        path: 'patStatus/p/:id/s/:screenNo',
        component:() => import('../components/Export/PatStatus.vue')
      },
      {
        path: 'project/:id',
        component:() => import('../components/Export/ProjectStatus.vue')
      },{
        path: 'question/:id',
        component:() => import('../components/Export/QuestionExport.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

//路由守卫
router.beforeEach(to => {
  if (!to.fullPath.startsWith('/pdf')) {
    if (to.name !== "login" && IsLogin()) return { name: 'login' };
  }

});

export default router


function IsLogin() {
  var token = localStorage.getItem('token');
  return token == "" || token == null;
}