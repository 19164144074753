import axios from '@/utils/request.js'

const questionApi = {
    add(question) {
        return axios.post('/Question/Add', question);
    },
    getLogs(fileUniqueId, dataItem) {
        return axios.get(`/Question/GetLogs?fileUniqueId=${fileUniqueId}&dataItem=${dataItem}`);
    },
    getLogsById(fileUniqueId) {
        return axios.get('/Question/GetLogsByFileUniqueId?fileUniqueId=' + fileUniqueId);
    },
    clearQuestion(question) {
        return axios.put('/Question/ClearQuestion', question);
    },
    closeQuestion(question) {
        return axios.put('/Question/CloseQuestion', question);
    },
    getZyChartsData(hospitalNo, projectId) {
        return axios.get(`/Question/GetZyChartsData?projectId=${projectId}&hospitalNo=${hospitalNo}`)
    },
    getQuestionsByProjectId(projectId) {
        return axios.get('/Question/GetQuestionsByProjectId?projectId=' + projectId);
    },
    getQuestionCount(projectId, hospitalNo) {
        return axios.get('/Question/GetQuestionCount?projectId=' + projectId + "&hospitalNo=" + hospitalNo);
    },
    checkLogCancel(fileUniqueId, dataItem) {
        return axios.get(`/Question/CheckLogCancel?fileUniqueId=${fileUniqueId}&dataItem=${dataItem}`);
    },
    CancelQuestion(cancelZy) {
        return axios.post(`/Question/CancelQuestion`, cancelZy);
    },
    getQuestion(questionId){
        return axios.get('/Question/GetQuestion?questionId=' + questionId);
    }

}

export default questionApi