import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import '@/assets/css/global.css'
import 'element-plus/theme-chalk/display.css'
import api from '@/api/index.js'
import crf from '@/utils/crf.js'
import './assets/css/templete.css'
//引入字典
import dict from '@/utils/dict.js'
import helper from '@/utils/helper.js'

import locale from 'element-plus/dist/locale/zh-cn.mjs'

import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

app.config.globalProperties.$api = api
app.config.globalProperties.$helper = helper
app.config.globalProperties.$crf = crf
app.config.globalProperties.$dict = dict
app.use(ElementPlus, { locale });
app.use(store).use(router).mount('#app')


