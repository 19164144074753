<template>
    <el-dialog v-model="showDialog" title="系统通知" :close-on-click-modal="false" width="40%" @closed="frmClosed">
        <div v-for="item in updateList" :key="item.id">
            <div class="p-title">
                <h3>{{ item.title }}</h3>
                <span>{{ $helper.getDate(item.createTime, 16) }}</span>
            </div>

            <div class="p-content">
                <p v-for="(content, index) in item.content" :key="index">
                    {{ content }}
                </p>
            </div>
        </div>
    </el-dialog>
</template>

<script>

export default {
    props: {
        show: {
            type: Boolean
        },
        list: {
            type: Array
        }
    },
    data() {
        return {

        }
    },
    methods: {
        created() {
            console.log(this.list);
        },
        frmClosed() {
            this.showDialog = false;
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.show;
            },
            set(val) {
                this.$emit("closeStatusDialog", val);
            }
        },
        updateList() {
            let arr = [];
            this.list.forEach(x => {
                let content = x.content.replace(/[\n\r]/g, '|');

                arr.push({
                    title: x.title,
                    content: content.split('|'),
                    createTime: x.createTime
                })
            })
            console.log(arr)
            return arr;
        }
    }
}
</script>

<style lang="scss" scoped>
.p-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
        margin-left: 24px;
    }
}

.p-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    p {
        text-align: left;
        margin: 6px 0;
    }
}
</style>
