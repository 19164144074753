<template>
    <el-dialog title="修改密码" width="30%" draggable v-model="showDialog" @closed="frmClosed">

        <el-form :model="pwd" label-width="120px" :rules="rules" ref="pwdForm">
            <el-form-item label="原密码" prop="oldPwd">
                <el-input v-model="pwd.oldPwd" type="password" show-password />
            </el-form-item>

            <el-form-item label="新密码" prop="newPwd">
                <el-input v-model="pwd.newPwd" type="password" show-password />
            </el-form-item>

            <el-form-item label="再次输入" prop="newPwd2">
                <el-input v-model="pwd.newPwd2" type="password" show-password />
            </el-form-item>
        </el-form>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="showDialog = false">取消</el-button>
                <el-button type="primary" @click="onSubmit()">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'

export default {
    props: {
        show: {
            type: Boolean,
            defaule: false
        }
    },
    data() {
        return {
            pwd: {
                oldPwd: "",
                newPwd: "",
                newPwd2: ""
            },
            rules: {
                oldPwd: [
                    { required: true, message: "请输入密码", tigger: blur }
                ],
                newPwd: [
                    { required: true, message: "请输入新密码", tigger: blur }
                ],
                newPwd2: [
                    { required: true, message: "请再次输入新密码", tigger: blur }
                ]
            }
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        },
        showDialog: {
            get() {
                return this.show;
            },
            set(val) {
                this.$emit('closePwd', val);
            }
        }
    },
    methods: {
        frmClosed() {
            this.pwd = {
                oldPwd: "",
                newPwd: "",
                newPwd2: ""
            };
        },
        onSubmit() {
            this.$refs["pwdForm"].validate(async (valid) => {
                if (valid) {
                    if (this.pwd.newPwd != this.pwd.newPwd2) {
                        ElMessage.error("两次密码不一样，请重新输入！");
                        this.pwd.newPwd2 = "";
                        return;
                    }
                    await this.changePwd();
                }
            });
        },
        async changePwd() {
            let user = {
                loginName: this.currentUser.loginName,
                oldPwd: this.pwd.oldPwd,
                newPwd: this.pwd.newPwd
            }

            let result = await this.$api.userApi.changePwd(user);

            if (result.status === 1) {
                ElMessage.success("修改成功");
                this.showDialog = false;
                return;
            }
            ElMessage.error(result.message);
        }
    }
}
</script>

<style></style>