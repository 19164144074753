import axios from '@/utils/request.js'

const patApi = {
    getAll() {
        return axios.get("/patient/GetAll");
    },
    getPatientByScreenNo(screenNo, projectId) {
        return axios.get(`/patient/GetPatientByScreenNo?screenNo=${screenNo}&projectId=${projectId}`);
    },
    add(pat) {
        return axios.post('/patient/add', pat);
    },
    edit(pat) {
        return axios.put('/patient/edit', pat);
    },
    getPatientByProjectId(projectId) {
        return axios.get('/patient/GetPatientByProjectId?projectId=' + projectId);
    },
    changeStatus(pat) {
        return axios.put('/patient/ChangeStatus', pat);
    },
    getAdtLogs(screenNo, projectId) {
        return axios.get(`/patient/GetAdtLogs?screenNo=${screenNo}&projectId=${projectId}`);
    },
    getInGroupSum(hospitalNo, projectId) {
        return axios.get(`/patient/GetInGroupSum?hospitalNo=${hospitalNo}&projectId=${projectId}`)
    },
    getInGroupSumByHospital(hospitalNo, projectId) {
        return axios.get(`/patient/GetInGroupSumByHospital?hospitalNo=${hospitalNo}&projectId=${projectId}`)
    },
    getPatSums(hospitalNo, projectId) {
        return axios.get(`/patient/GetPatSums?hospitalNo=${hospitalNo}&projectId=${projectId}`);
    },
    getPatById(screenNo, projectId) {
        return axios.get(`/patient/GetPatById?screenNo=${screenNo}&projectId=${projectId}`);
    },
    resetStatus(data) {
        return axios.post('/patient/ResetStatus', data);
    }
}

export default patApi