import axios from '@/utils/request.js'

const userApi = {
    login(loginName, password) {
        return axios.post('/users/login', {
            loginName: loginName,
            password: password
        })
    },
    getAll() {
        return axios.get("/users/GetAllUsers");
    },
    add(user) {
        return axios.post('/users/add', user);
    },
    edit(user) {
        return axios.put('/users/edit', user);
    },
    getAllDoctors() {
        return axios.get('/users/GetAllDoctors');
    },
    getUser(id) {
        return axios.get('/users/GetUser?loginName=' + id);
    },
    changePwd(user) {
        return axios.put('/users/ChangePwd', user);
    },
    delUser(loginName) {
        return axios.post('/users/DelUser?loginName=' + loginName);
    }
}

export default userApi