import { ElMessage, ElMessageBox } from 'element-plus'
import api from '@/api/index.js'
import * as XLSX from 'xlsx';

const crf = {
    //显示质疑窗体
    async showAddZy(fileUniqueId, controlItem) {
        let result = await api.questionApi.getLogs(fileUniqueId, controlItem);

        if (result.status === 1) {

            if (result.data.length == 0 || result.data[0].status == "-1") {
                return true;
            }

            if (result.data[0].status == "1") {
                ElMessage.error("当前有未处理的质疑");
                return false;
            }

            if (result.data[0].status == "2") {
                ElMessage.error("当前有未关闭的质疑");
                return false;
            }

            if (result.data[0].status == "3") {

                return true;
            }
        } else {
            return false;
        }

    },
    //关闭质疑
    async cancelZy(fileUniqueId, controlItem, loginName, dataItem) {
        let result = await api.questionApi.getLogs(fileUniqueId, controlItem);
        if (result.status === 1) {
            if (result.data.length == 0 || result.data[0].status == "-1") {
                ElMessage.info("当前变量不存在质疑");
                return false;
            }
            else {
                if (result.data[0].status == '3') {
                    ElMessage.info("该质疑已关闭");
                    return false;
                }
                if (result.data[0].status == '1') {
                    ElMessage.info("该质疑未澄清");
                    return false;
                }
                ElMessageBox.prompt('取消质疑描述', '取消质疑', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: "",
                    inputErrorMessage: ""
                }).then(async ({ value }) => {
                    let q = {
                        questionId: result.data[0].questionId,
                        closeDesc: value,
                        closeUser: loginName
                    }
                    let result2 = await api.questionApi.closeQuestion(q);
                    if (result2.status === 1) {
                        ElMessage.success("该质疑已关闭");
                        dataItem.style.border = "solid 2px blue";
                        return true;
                    } else {
                        ElMessage.error(result2.message);
                    }
                }).catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消输入',
                    })
                })
            }
        }

        return false;
    },
    async zyClose(fileUniqueId, controlItem, loginName, dataItem) {
        let result = await api.questionApi.checkLogCancel(fileUniqueId, controlItem);

        if (result.status !== 1) {
            ElMessage({
                type: 'info',
                message: result.message,
            });
            return false;
        }

        let dto = {
            guid: result.data.questionId,
            user: loginName,
            cancelDesc: ''
        };

        ElMessageBox.confirm(
            `确定要取消质疑吗？`,
            '取消质疑',
            {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }
        )
            .then(async () => {
                let result2 = await api.questionApi.CancelQuestion(dto);
                if (result2.status === 1) {
                    ElMessage({
                        type: 'success',
                        message: '取消质疑成功',
                    });
                    dataItem.style.border = "solid 2px green";
                    return true;
                } else {
                    ElMessage({
                        type: 'error',
                        message: '取消质疑失败',
                    });
                }

            })
            .catch(() => {
                console.log('cancel')
            })

        return false;
    },
    //澄清质疑
    async clearZy(fileUniqueId, controlItem) {
        let result = await api.questionApi.getLogs(fileUniqueId, controlItem);
        let zyInfo = null;
        if (result.status === 1) {
            result.data.forEach(q => {
                if (q.status == "1") {
                    zyInfo = q;
                    return;
                }
            });
            if (zyInfo == null) {
                ElMessage.info("当前变量没有需要澄清的质疑");
            }
        }
        else {
            ElMessage.error("查询质疑失败");
        }
        return zyInfo;
    },
    showDialogWrapper(fileFlag) {
        return fileFlag == "1" || fileFlag == '2' || fileFlag == '3';
    },
    // export(dataSource, formName) {
    //     let baseInfo = dataSource.baseInfo;
    //     let jsonObj = {};
    //     jsonObj["方案编号"] = baseInfo.projectId;
    //     jsonObj["筛选号"] = baseInfo.screenNo;
    //     jsonObj["姓名缩写"] = baseInfo.pym;
    //     jsonObj["访视编号"] = baseInfo.lifeTime;
    //     jsonObj["访视名称"] = baseInfo.lifeTimeName;

    //     for (let key in dataSource.data) {
    //         let dataItem = document.querySelector(`[data-item='${key}']`);
    //         if (dataItem == null) continue;
    //         let desc = dataItem.dataset.desc;
    //         if (dataSource.data[key] instanceof Array) {
    //             jsonObj[desc] = dataSource.data[key].join(",");
    //         }
    //         else {
    //             jsonObj[desc] = dataSource.data[key];
    //         }
    //     }
    //     const workbook = XLSX.utils.book_new();
    //     const worksheet = XLSX.utils.json_to_sheet([jsonObj]);
    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    //     let fileName = `${baseInfo.projectId}_${baseInfo.screenNo}_${baseInfo.lifeTime}_${baseInfo.lifeTimeName}_${formName}.xlsx`;
    //     XLSX.writeFile(workbook, fileName);
    // },
    //更新文书内容
    async updateFileContent(fileUniqueId, data) {
        let fileContent = JSON.stringify(data);
        return await api.fileApi.updateFileContent({
            fileContent: fileContent,
            fileUniqueId: fileUniqueId
        });
    },
    async updateEleOperLog(fileUniqueId, dataItem, user) {
        let itemList = [];
        itemList.push({
            controlId: dataItem.dataset.item,
            controlDesc: dataItem.dataset.desc,
            controlValue: dataItem.dataset.val,
            controlType: dataItem.dataset.type
        });

        var log = {
            fileUniqueId: fileUniqueId,
            action: "澄清质疑",
            userId: user,
            controls: itemList
        }
        await api.logApi.addEleOpers(log);
    },
    async insertEleOperLog(fileUniqueId, item, user) {
        let itemClass = "." + fileUniqueId + " [data-item='" + item + "']";
        console.log(itemClass);
        let dataItem = document.querySelector(itemClass);
        if (!dataItem) return;
        let itemList = [];
        itemList.push({
            controlId: dataItem.dataset.item,
            controlDesc: dataItem.dataset.desc,
            controlValue: dataItem.dataset.val,
            controlType: dataItem.dataset.type
        });

        var log = {
            fileUniqueId: fileUniqueId,
            action: "澄清质疑",
            userId: user,
            controls: itemList
        }
        await api.logApi.addEleOpers(log);
    },
    async zyCleard(fileUniqueId, questions, currentQuestion, dataSource) {
        let dataItem;
        questions.forEach((q, i) => {
            if (q.questionId == currentQuestion.questionId) {

                 let itemClass = "." + fileUniqueId + " [data-item='" + q.dataItem + "']";
                 dataItem = document.querySelector(itemClass);
                if (dataItem.dataset.type == "checkbox") {
                    dataSource.data[`${q.dataItem}`] = JSON.parse(currentQuestion.newValue);
                } else {
                    dataSource.data[`${q.dataItem}`] = currentQuestion.newValue;
                }
                questions.splice(i, 1, currentQuestion);
                return;
            }
        });

        //await this.updateFileContent(fileUniqueId, dataSource.data);
        //await this.updateEleOperLog(fileUniqueId, dataItem, user);
    },
    async addEleOperLog(fileUniqueId, user, action) {
        let itemClass = "." + fileUniqueId + " .data-item";
        var dataItems = document.querySelectorAll(itemClass);
        let itemList = [];
        dataItems.forEach(item => {
            itemList.push({
                controlId: item.dataset.item,
                controlDesc: item.dataset.desc,
                controlValue: item.dataset.val,
                controlType: item.dataset.type
            });
        })

        var log = {
            fileUniqueId: fileUniqueId,
            action: action,
            userId: user,
            controls: itemList
        }
        await api.logApi.addEleOpers(log);
    },
    async addOperLog(fileUniqueId, user, action) {
        var operLog = {
            fileUniqueId: fileUniqueId,
            action: action,
            userId: user,
        }
        await api.logApi.addOpers(operLog);
    },
    async saveEle(templetCode, user) {
        let itemClass = ".t-content .data-item";
        let dataItems = document.querySelectorAll(itemClass);
        let arr = [];
        dataItems.forEach((x, i) => {
            if (!arr.some(a => a.eleId == x.dataset.item)) {
                arr.push({
                    templetCode: templetCode,
                    eleId: x.dataset.item,
                    eleName: x.dataset.desc,
                    eleType: x.dataset.type,
                    showOrder: i + 1,
                    createUser: user
                });
            }
        })
        let result = await api.templetApi.addEle(arr);
        if (result.status == 1) {
            ElMessage.success("添加成功");
        } else {
            ElMessage.error("添加失败");
        }
    },
    export(dataSource, formName) {
        let baseInfo = dataSource.baseInfo;
        let jsonObj = {};
        jsonObj["方案编号"] = baseInfo.projectId;
        jsonObj["筛选号"] = baseInfo.screenNo;
        jsonObj["姓名缩写"] = baseInfo.pym;
        jsonObj["访视编号"] = baseInfo.lifeTime;
        jsonObj["访视名称"] = baseInfo.lifeTimeName;

        let dataItems = document.querySelectorAll(`[data-item]`);
        let arr = [];

        dataItems.forEach(x => {
            if (!arr.some(a => a && a.dataset.item == x.dataset.item)) {
                arr.push(x);
            }
        })

        arr.forEach(x => {
            let desc = x.dataset.desc;
            jsonObj[desc] = x.dataset.val;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([jsonObj]);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        let fileName = `${baseInfo.projectId}_${baseInfo.screenNo}_${baseInfo.lifeTime}_${baseInfo.lifeTimeName}_${formName}.xlsx`;
        XLSX.writeFile(workbook, fileName);
    },
}

export default crf;