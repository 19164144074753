import axios from '@/utils/request.js'

const exportApi = {
    getPatsStatusChange(data) {
        return axios.post('/Export/GetPatsChangeStatus', data);
    },
    getPatStatusChange(projectId, screenNo) {
        return axios.get(`/Export/GetPatChangeStatus?projectId=${projectId}&screenNo=${screenNo}`);
    },
    exportPatStatusPDF(data) {
        return axios.post('/Export/ExportPatStatusPDF', data);
    },
    getProjectChangeLogs(data) {
        return axios.post('/Export/GetProjectChangeLogs', data);
    },
    exportProjectStatusPDF(data) {
        return axios.post('/Export/ExportProjectStatusPDF', data);
    },
    exportQuestionPDF(data) {
        return axios.post('/Export/ExportQuestionPDF', data);
    }
}

export default exportApi;