const dict = {
    projectStatusDict: [
        // { name: "库表设计中", value: 0 },
        { name: "项目进行中", value: 1 },
        // { name: "项目已启动", value: 2 },
        { name: "项目已锁库", value: 3 },
        // { name: "项目已结束", value: 4 },
        // { name: "项目已暂停", value: 5 },
    ],
    lifeTimeDict: [
        { id: "1", label: "1--筛选期 (D-21 ~ D1)" },
        { id: "2", label: "2--基线期D1" },
        { id: "3", label: "3--治疗期4周 (D28+5d)" },
        { id: "4", label: "4--治疗期12周 (D84+5d)" },
        { id: "5", label: "5--治疗期24周 (D168+5d)" },
        { id: "6", label: "6--治疗期36周 (D252+5d)" },
        { id: "7", label: "7--治疗期52周 (D364+5d)/提前退出" },
        { id: "500", label: "500--受试者日志-试验药物使用情况" },
        { id: "600", label: "600一不良事件" },
        { id: "700", label: "700--既往及合并用药" },
        { id: "800", label: "800--既往及合并非药物治疗" },
    ],
};
export default dict;