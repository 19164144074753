import axios from '@/utils/request.js'

const goalSettingApi = {
    getValue(category) {
        return axios.get(`GoalSetting/GetValueByCategory?category=` + category);
    },
    getTreatGroups(projectId) {
        return axios.get(`GoalSetting/GetTreatGroup?projectId=${projectId}`)
    },
    addTreatGroup(group) {
        return axios.post('GoalSetting/AddTreatGroup', group);
    },
    delTreatGroup(group) {
        return axios.post('GoalSetting/DelTreatGroup', group)
    },
    getElementDataSource(category) {
        return axios.get('GoalSetting/GetElementDataSource?category=' + category);
    }
}

export default goalSettingApi