const helper = {
    getDate(val, len) {
        if (val == null || val == undefined || val == "") return "";
        // if (val.length >= len) {
        //     return val.substring(0, len).replace("T", " ");
        // }
        //return val.replace("T", " ");

        let d = new Date(val);
        if (d == "Invalid Date") return "";

        var date = this.padZero(d.getDate(), 2);
        var year = d.getFullYear();
        var month = this.padZero(d.getMonth() + 1, 2);
        var h = this.padZero(d.getHours(), 2);
        var min = this.padZero(d.getMinutes(), 2);
        var sec = this.padZero(d.getSeconds(), 2);

        if (len == undefined || len == "") {
            return year + "-" + month + "-" + date;
        }

        var time = year + "-" + month + "-" + date + " " + h + ":" + min + ":" + sec;

        return time.substr(0, len);
    },
    padZero(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    },
    getAge(val) {
        var newVal = new Date(val);
        var year = newVal.getFullYear(); // 获取年份
        var month = newVal.getMonth() + 1; // 获取月份（注意需要加1，因为月份从0开始）
        if (month < 10) {
            month = "0" + month;
        }
        var day = newVal.getDate(); // 获取日期
        if (day < 10) {
            day = "0" + day;
        }
        val = year + "-" + month + "-" + day;
        let currentYear = new Date().getFullYear() //当前的年份
        let calculationYear = new Date(val).getFullYear() //计算的年份
        const wholeTime = currentYear + val.substring(4) //周岁时间
        const calculationAge = currentYear - calculationYear //按照年份计算的年龄
        //判断是否过了生日
        if (new Date().getTime() > new Date(wholeTime).getTime()) {
            return calculationAge
        } else {
            return calculationAge - 1
        }
    },
    getPatStatusName(status) {
        switch (status) {
            case -1:
                return "筛选";
            case 0:
                return "筛选失败";
            case 1:
                return "入组";
            case 2:
                return "脱落";
            case 3:
                return "完成";
        }
    },
    getProjectStatusName(status) {
        switch (status) {
            case 0:
                return "库表设计中";
            case 1:
                return "项目进行中";
            case 2:
                return "项目已启动";
            case 3:
                return "项目已锁库";
            case 4:
                return "项目已结束";
            case 5:
                return "项目已暂停";
        }
        return "未知状态";
    },
    getProjectStatusType(status) {
        switch (status) {
            case 0:
                return "";
            case 1:
                return "primary";
            case 2:
                return "info";
            case 3:
                return "danger";
            case 4:
                return "danger";
            case 5:
                return "warning";
        }
        return "info";
    },
}

export default helper;