import axios from '@/utils/request.js'

const projectApi = {
    getProjects() {
        return axios.get('/project/GetProjects');
    },
    add(project) {
        return axios.post('/project/AddProject', project);
    },
    getProjectById(id) {
        return axios.get('/project/GetProjectById?id=' + id);
    },
    // getProjectByHospital(hospital) {
    //     return axios.get('/project/GetProjectsByHospital?hospital=' + hospital);
    // },
    getProjectByUser(userId) {
        return axios.get('/project/GetProjectByUser?userId=' + userId);
    },
    changeStatus(pro) {
        return axios.put('/project/ChangeProjectStatus', pro);
    },
    updateProject(pro) {
        return axios.put('/project/ModifyProject', pro);
    },
    editProject(pro) {
        return axios.put('/project/EditProject', pro);
    },
    getProjectLogs(id) {
        return axios.get('/project/GetProjectLog?projectId=' + id);
    },
    getAeReport(projectId, hospitalNo) {
        return axios.get('/AeRecord/GetAeReport?projectId=' + projectId + "&hospitalNo=" + hospitalNo);
    },
    getHospitals(projectId) {
        return axios.get('/project/GetHospitals?projectId=' + projectId);
    },
    removeHospital(projectId, hospitalNo) {
        return axios.delete(`/project/RemoveHospital?projectId=${projectId}&hospitalNo=${hospitalNo}`);
    },
    addHospital(pro) {
        return axios.post("/project/AddHospital", pro);
    },
    getUsers(projectId) {
        return axios.get('/project/GetUsers?projectId=' + projectId);
    },
    getUsersByHospital(hospitalNo) {
        return axios.get('/project/GetUsersByHospital?hospitalNo=' + hospitalNo);
    },
    addUsers(pro) {
        return axios.post('/project/AddUsers', pro);
    },
    deleteUser(id) {
        return axios.delete('/project/DeleteUser?id=' + id);
    },
    getLifetimes(projectId) {
        return axios.get('/project/GetLifetimes?projectId=' + projectId);
    },
    getTempletsByLifetime(projectId, lifetimeCode) {
        return axios.get(`/project/GetTempletsByLifetime?projectId=${projectId}&lifetimeCode=${lifetimeCode}`);
    },
    renameLifetime(lifetime) {
        return axios.put('/project/RenameLifetime', lifetime);
    },
    removeProVsLifetime(id) {
        return axios.delete('/project/RemoveProVsLifetime?id=' + id)
    },
    getHospitalByProjectId(projectId) {
        return axios.get('/project/GetHospitalByProjectId?projectId=' + projectId);
    },
    addLifetime(projectId, lifetimeName, user) {
        return axios.post(`/project/AddLifetime?projectId=${projectId}&lifetimeName=${lifetimeName}&user=${user}`);
    },
    getExportData(data) {
        return axios.post('/project/GetExportData', data);
    },
    exportPDF(data){
        return axios.post('/Export/ExportPDF',data);
    },
    getFormCondition(screenNo, projectId) {
        return axios.get(`/project/GetFormCondition?screenNo=${screenNo}&projectId=${projectId}`);
    },
    getAll(){
        return axios.get('/project/GetAll');
    }

}

export default projectApi