import axios from '@/utils/request.js'

const logApi = {
    getEleOperLogs(fileUniqueId,controlId) {
        return axios.get(`/OperLog/GetEleOperLogs?fileUniqueId=${fileUniqueId}&controlId=${controlId}`);
    },
    getOperLogs(fileUniqueId) {
        return axios.get(`/OperLog/GetOperLogs?fileUniqueId=${fileUniqueId}`);
    },
    addEleOpers(log) {
        return axios.post('/OperLog/AddEleOpers', log);
    },
    addOpers(log) {
        return axios.post('/OperLog/AddOperLog', log);
    }
}

export default logApi