<template>
  <div class="home">
    <!-- 搜索栏 -->
    <div class="search-wrapper">
      <div class="p-left">
        <el-form :inline="true" :model="condition" class="filter-form">
          <el-form-item label="项目编号">
            <el-input v-model="condition.code" placeholder="项目编号" />
          </el-form-item>
          <el-form-item label="项目名称">
            <el-input v-model="condition.name" placeholder="项目名称" />
          </el-form-item>
          <el-form-item label="项目状态">
            <el-select v-model="condition.status" placeholder="项目状态">
              <el-option label=" " :value="-1" />
              <el-option v-for="pro in projectStatusDict" :key="pro.value" :label="pro.name" :value="pro.value" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="filter" size="small">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-if="currentUser.roleId == '001'" @click="exportExcel"
              size="small">导出项目变更日志Excel</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-if="currentUser.roleId == '001'" @click="exportProjectStatusPDF" size="small"
              :loading="downloadProjectStatusPDF">导出项目变更日志PDF</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="p-right">
        <el-button type="primary" @click="showProject = true" v-if="currentUser.roleId == '001'" link>新增项目</el-button>
        <el-button link v-if="0"><el-icon>
            <Menu />
          </el-icon></el-button>

        <el-button link v-if="0"><el-icon>
            <Grid />
          </el-icon></el-button>
      </div>
    </div>

    <!-- 项目信息表格 -->
    <el-table :data="projectsFilter" border style="width: 100%" stripe v-loading="loading"
      @selection-change="handleSelectionChange" v-if="dataView == 'table' && projectsFilter.length > 0"
      table-layout="auto" :height="tableHeight">
      <el-table-column type="selection" width="40" />
      <el-table-column prop="projectId" label="项目编号" />
      <el-table-column label="项目名称">
        <template #default="scope">
          <!-- <router-link :to="'/project/' + scope.row.projectId + '/pat'" class="link" v-if="scope.row.status != 0">{{ scope.row.projectName }}</router-link>
          <router-link :to="'/project/' + scope.row.projectId + '/setting'" class="link" v-if="scope.row.status == 0">{{ scope.row.projectName }}</router-link> -->
          <el-text tag="ins" type="primary" @click="goProject(scope.row)">{{ scope.row.projectName }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="createId" label="申办者ID" v-if="0" />
      <el-table-column prop="requetor" label="申办者" />
      <el-table-column prop="status" label="项目状态" v-if="0" />
      <el-table-column label="项目状态">
        <template #default="scope">
          <el-text class="mx-1" :type="$helper.getProjectStatusType(scope.row.status)">{{
            $helper.getProjectStatusName(scope.row.status) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template #default="scope">
          <span>{{ $helper.getDate(scope.row.createDateTime, 16) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="totalCount" label="患者总数" v-if="showCount" />
      <el-table-column prop="inGroupCount" label="入组" v-if="showCount" />
      <el-table-column prop="screenCount" label="筛选中" v-if="showCount" />
      <el-table-column prop="screenFailCount" label="筛选失败" v-if="showCount" />
      <el-table-column prop="fallOutCount" label="脱落" v-if="showCount" />
      <el-table-column prop="completedCount" label="已完成" v-if="showCount" />
      <!-- <el-table-column label="所属中心">
        <template #default="scope">
          <el-tag class="ml-2" type="success" v-for="item in scope.row.hospitals" :key="item.hospitalNo"
            style="margin: 0 4px;">{{
              item.hospitalName }}</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" v-if="currentUser.roleId == '001' || currentUser.roleId == '003'" fixed="right">
        <template #default="scope">
          <el-button type="primary" link @click="edit(scope.row)" :disabled="scope.row.status == 3"><el-icon>
              <EditPen />
            </el-icon></el-button>
          <el-button type="primary" link @click="changeStatus(scope.row)"><el-icon>
              <Switch />
            </el-icon></el-button>
          <el-button type="primary" link @click="showLogs(scope.row.projectId)"><el-icon>
              <Tickets />
            </el-icon></el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-empty description="空空如也" v-if="projectsFilter.length == 0" />

    <div class="card-wrapper" v-if="dataView == 'card'">
      <el-card v-for="pro in projectsFilter" :key="pro.projectId" class="box-card" style="width: 350px" shadow="hover"
        :class="'border' + pro.status" @click="go(pro.projectId)">
        <template #header>
          <div class="card-header">
            <el-text size="large" :type="$helper.getProjectStatusType(pro.status)" style="margin-right: auto;">{{
              pro.projectName
            }}
            </el-text>
            <el-button type="primary" link @click.stop="edit(pro)"><el-icon>
                <EditPen />
              </el-icon></el-button>
            <el-button type="primary" link @click.stop="changeStatus(pro)"><el-icon>
                <Switch />
              </el-icon></el-button>
            <el-button type="primary" link @click.stop="showLogs(pro.projectId)"><el-icon>
                <Tickets />
              </el-icon></el-button>
          </div>
        </template>
        <el-descriptions :column="2">
          <el-descriptions-item label="ID">{{ pro.projectId }}</el-descriptions-item>
          <el-descriptions-item label="申办者">{{ pro.requetor }}</el-descriptions-item>
          <el-descriptions-item label="状态">
            <el-text class="mx-1" :type="$helper.getProjectStatusType(pro.status)">{{
              $helper.getProjectStatusName(pro.status) }}</el-text>
          </el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ $helper.getDate(pro.createDateTime, 10) }}</el-descriptions-item>
          <el-descriptions-item label="患者总数"><el-text>{{ pro.totalCount }}</el-text> </el-descriptions-item>
          <el-descriptions-item label="筛选中"><el-text type="warning">{{ pro.screenCount }}</el-text>
          </el-descriptions-item>
          <el-descriptions-item label="筛选失败"><el-text type="danger">{{ pro.screenFailCount }}</el-text>
          </el-descriptions-item>
          <el-descriptions-item label="入组"><el-text type="primary">{{ pro.inGroupCount }}</el-text>
          </el-descriptions-item>
          <el-descriptions-item label="脱落"><el-text type="danger">{{ pro.fallOutCount }}</el-text>
          </el-descriptions-item>
          <el-descriptions-item label="完成"><el-text type="success">{{ pro.completedCount }}</el-text>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
    </div>

    <!-- 新增项目弹出层 -->
    <el-dialog v-model="showProject" title="新增项目" width="30%" :close-on-click-modal="false">
      <el-form :model="project" label-width="120px" :rules="rules">
        <el-form-item label="项目编码" prop="projectId">
          <el-input v-model="project.projectId" autocomplete="off" />
        </el-form-item>
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="project.projectName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="中心选择" prop="hospitals">
          <el-select v-model="project.hospitals" multiple placeholder="选择中心">
            <el-option v-for="hos in hospitals" :key="hos.hospitalNo" :label="hos.hospitalName" :value="hos.hospitalNo" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showProject = false">取消</el-button>
          <el-button type="primary" @click="addProject">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 项目状态调整弹出层 -->
    <el-dialog v-model="showStatus" title="项目状态" width="30%" :close-on-click-modal="false">
      <el-form :model="projectStatus" label-width="120px" :rules="statusRules">
        <el-form-item label="项目编码" prop="projectId">
          <el-input v-model="projectStatus.projectId" disabled />
        </el-form-item>
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="projectStatus.projectName" disabled />
        </el-form-item>
        <el-form-item label="项目状态" prop="status">
          <el-select v-model="projectStatus.status" placeholder="项目状态">
            <el-option v-for="pro in projectStatusDict" :key="pro.value" :label="pro.name" :value="pro.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="调整说明" prop="projectName">
          <el-input v-model="projectStatus.remark" autocomplete="off" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showStatus = false">取消</el-button>
          <el-button type="primary" @click="changeStatusSubmit">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 项目编辑弹出层 -->
    <el-dialog v-model="showEdit" title="编辑" width="30%" :close-on-click-modal="false">
      <el-form :model="projectEdit" label-width="120px" :rules="editRules">
        <el-form-item label="项目编码">
          <el-input v-model="projectEdit.projectId" disabled />
        </el-form-item>
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="projectEdit.projectName" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showEdit = false">取消</el-button>
          <el-button type="primary" @click="editProject">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 项目日志弹出层-->
    <el-dialog v-model="showLog" title="项目日志" width="50%" :close-on-click-modal="false" @closed="projectLogs = []">
      <el-table :data="projectLogs" style="width: 100%" stripe v-loading="loadingLog">
        <el-table-column prop="projectId" label="项目编号" />
        <el-table-column label="项目状态">
          <template #default="scope">
            <el-text class="mx-1" :type="$helper.getProjectStatusType(scope.row.status)">{{
              $helper.getProjectStatusName(scope.row.status)
            }}</el-text>
          </template>
        </el-table-column>
        <el-table-column prop="operator" label="操作者" />
        <el-table-column prop="operateTime" label="操作时间" />
        <el-table-column prop="remark" label="说明" />
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="showLog = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 更新弹出层 -->
    <UpdateRemind :list="updateRemindList" :show="showUpdateRemind"/>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import UpdateRemind from '@/components/Dialogs/UpdateRemind.vue'
import * as XLSX from 'xlsx';

export default {
  components: {
    UpdateRemind
  },
  data() {
    return {
      selectedPro: [],
      downloadProjectStatusPDF: false,
      dataView: 'table',
      showProject: false,
      showEdit: false,
      showLog: false,
      loading: true,
      loadingLog: true,
      condition: {
        code: "",
        name: "",
        status: -1,
        hospital: ""
      },
      project: {
        projectId: '',
        projectName: '',
        hospitals: []
      },
      projects: [],
      projectsFilter: [],
      oldStatus: -1,
      oldProjectName: "",
      projectStatus: {
        projectId: "",
        projectName: "",
        status: -1,
        remark: ""
      },
      projectEdit: {
        projectId: "",
        projectName: ""
      },
      showStatus: false,
      showUpdateRemind: false,
      updateRemindList: [],
      rules: {
        projectId: [
          { required: true, message: '请输入项目编码', trigger: 'blur' },
          { pattern: /^[A-Za-z0-9]+$/, message: '只能由字母或数字组成', trigger: 'blur' }
        ],
        projectName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
        ],
        hospitals: [
          { required: true, message: '请选择中心', trigger: 'blur' },
        ]
      },
      statusRules: {
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ]
      },
      editRules: {
        projectName: [
          { required: true, message: '请填写项目名称', trigger: 'blur' },
        ]
      }
    };
  },
  async created() {
    this.$store.commit('clearCurrentProject');//清空当前项目
    let uid = localStorage.getItem('uid');

    if (uid == undefined) return;

    var result = await this.$api.projectApi.getProjectByUser(uid);
    this.loading = false;
    if (result.status === 1) {
      this.projects = result.data;
      this.projectsFilter = this.projects;
    }

    //检查升级内容
    let updateResult = await this.$api.appUpdateApi.getRemindByUser(this.currentUser.loginName);
    if (updateResult.status === 1 && updateResult.data.length > 0) {
       let remindIds = localStorage.getItem('ids');

      if (!remindIds) {
        remindIds = [];
      } else {
        remindIds = remindIds.split(',');
      }
      let arr = [];
      updateResult.data.forEach(x => {
        // setTimeout(() => {
        //   if (!remindIds.includes(x.id.toString())) {
        //     ElNotification({
        //       title: x.title,
        //       dangerouslyUseHTMLString: true,
        //       message: x.content.replace(/[\n\r]/g, "<br/>") + '<br/><i style="text-align:right;">' + this.$helper.getDate(x.createTime, 16) + '</i>',
        //       type: 'success',
        //       duration: 10000,
        //     });
        //     remindIds.push(x.id);
        //     localStorage.setItem('ids', remindIds.toString());
        //   }
        // }, 100 + i * 1000)
        if (!remindIds.includes(x.id.toString())) {
          remindIds.push(x.id);
          arr.push(x);
        }
      });

      if (arr.length > 0) {
        this.updateRemindList = arr;
        this.showUpdateRemind = true;
        localStorage.setItem('ids', remindIds.toString());
      }
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.selectedPro = val;
    },
    async exportExcel() {
      if (this.selectedPro.length == 0) {
        ElMessage.info("请选择需要导出的项目");
        return;
      }

      var pros = [];
      this.selectedPro.forEach(x => {
        pros.push(x.projectId);
      })

      let result = await this.$api.exportApi.getProjectChangeLogs(pros);
      if (result.status === 1) {
        let excelName = "项目变更日志" + ".xlsx";
        const workbook = XLSX.utils.book_new();

        let excelHeader = ['项目ID', '项目名称', '状态', '变更说明', '操作人', '操作时间'];
        let jsonData = [];
        jsonData.push(excelHeader);
        result.data.forEach(x => {
          var arr = [];
          arr.push(x.projectId);
          arr.push(x.projectName);
          arr.push(x.statusName);
          arr.push(x.remark);
          arr.push(x.operator);
          arr.push(this.$helper.getDate(x.operateTime, 16));

          jsonData.push(arr);
        })

        const columnsWidths = [];

        for (let i = 0; i < excelHeader.length; i++) {
          columnsWidths.push({
            wch: 20
          })
        }


        const worksheet = XLSX.utils.aoa_to_sheet(jsonData);
        worksheet['!cols'] = columnsWidths;
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, excelName);
      }

    },
    async exportProjectStatusPDF() {
      if (this.selectedPro.length == 0) {
        ElMessage.info("请选择需要导出的项目");
        return;
      }

      var pros = [];

      this.selectedPro.forEach(x => {
        pros.push(x.projectId);
      });

      this.downloadProjectStatusPDF = true;
      let result = await this.$api.exportApi.exportProjectStatusPDF(pros);
      if (result.status === 1) {
        this.download(result.data);
        ElMessage.success("导出PDF成功");
      } else {
        ElMessage.error(result.message);
      }
      this.downloadProjectStatusPDF = false;
    },
    download(file) {
      var downloadLink = document.createElement('a');
      downloadLink.href = '/' + file; // 替换为你的文件下载链接
      downloadLink.download = "项目变更日志"; // 替换为你想要的文件名

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    async addProject() {

      this.project.requetor = this.currentUser.loginName;
      var result = await this.$api.projectApi.add(this.project);
      if (result.status === 1) {
        this.showProject = false;
        var res = await this.$api.projectApi.getProjectByUser(this.currentUser.loginName);
        this.projects = res.data;
        this.projectsFilter = this.projects;
      }
    },
    filter() {
      this.projectsFilter = this.projects.filter(x => {
        var bool1 = true;
        if (this.condition.code != null) {
          bool1 = x.projectId.indexOf(this.condition.code) != -1;
        }
        var bool2 = true;
        if (this.condition.name != null) {
          bool2 = x.projectName.indexOf(this.condition.name) != -1;
        }
        var bool3 = true;
        if (this.condition.status >= 0) {
          if (x.status !== this.condition.status) {
            bool3 = false;
          }
        }
        // var bool4 = true;
        // if (this.condition.hospital != "" && this.condition.hospital != "*") {
        //   bool4 = x.hospitals.some(item => item.hospitalNo == this.condition.hospital || item.hospitalNo == '*')
        // }
        return bool1 && bool2 && bool3;
      });
    },
    changeStatus(pro) {
      this.oldStatus = pro.status;
      this.projectStatus.projectId = pro.projectId;
      this.projectStatus.projectName = pro.projectName;
      this.projectStatus.status = pro.status;
      this.showStatus = true;
    },
    async changeStatusSubmit() {
      if (this.oldStatus == this.projectStatus.status) {
        ElMessage.info("项目状态未发生改变");
        return;
      }
      this.projectStatus.operator = this.currentUser.loginName;
      var result = await this.$api.projectApi.changeStatus(this.projectStatus);
      if (result.status === 1) {
        this.projects.forEach(p => {
          if (p.projectId == this.projectStatus.projectId) {
            p.status = this.projectStatus.status;
            this.filter();
          }
        });
        this.showStatus = false;
      }
      else {
        ElMessage.error(result.message);
      }
    },
    async edit(pro) {
      this.oldProjectName = pro.projectName;
      this.showEdit = true;
      this.projectEdit = {
        projectId: pro.projectId,
        projectName: pro.projectName
      }
    },
    async editProject() {
      if (this.oldProjectName == this.projectEdit.projectName) {
        ElMessage.info("项目未发生改变");
        return;
      }
      var result = await this.$api.projectApi.editProject(this.projectEdit);
      if (result.status === 1) {
        this.projects.forEach(p => {
          if (p.projectId == this.projectEdit.projectId) {
            p.projectName = this.projectEdit.projectName;
            this.filter();
          }
        });
        this.showEdit = false;
        ElMessage.success("修改成功");
      }
      else {
        ElMessage.success(result.message);
      }
    },
    async showLogs(id) {
      this.loadingLog = true;
      this.showLog = true;
      var result = await this.$api.projectApi.getProjectLogs(id);
      this.loadingLog = false;
      if (result.status === 1) {
        this.projectLogs = result.data;
      }
    },
    go(id) {
      this.$router.push(`/project/${id}/pat`);
    },
    refresh() {
      //刷新
      let uid = localStorage.getItem('uid');
      if (uid == undefined || uid == '') {
        return;
      }


    },
    goProject(pro) {
      let roleId = this.currentUser.roleId;
      let status = pro.status;

      if (roleId == '001') {
        if (status == 0) {
          this.$router.push(`/project/${pro.projectId}/setting`);
        }
        else {
          this.$router.push(`/project/${pro.projectId}/pat`);
        }
      } else {
        if (status == 0) {
          ElMessage.error(`${pro.projectName}处于库表设计状态，暂时无法查看！`);
          return;
        }
        else {
          this.$router.push(`/project/${pro.projectId}/pat`);
        }
      }



    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    hospitals() {
      if (this.currentUser.hospitalNo == "*") {
        return this.$store.state.hospitals;
      }
      else {
        return this.$store.state.hospitals.filter(h => h.hospitalNo == this.currentUser.hospitalNo);
      }
    },
    projectStatusDict() {
      return this.$dict.projectStatusDict;
    },
    tableHeight() {
      return this.$store.state.innerHeight - 170;
    },
    showCount() {
      if (this.currentUser.roleId == '001' || this.currentUser.roleId == '007') {
        return 1;
      }
      return 0
    }
  },
  watch: {
    'condition.code'() {
      this.filter();
    },
    'condition.hospital'() {
      this.filter();
    },
    'condition.status'() {
      this.filter();
    },
    'condition.name'() {
      this.filter();
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  padding: 12px;
}

.link {
  --el-link-text-color: var(--el-color-primary);
  --el-link-hover-text-color: var(--el-color-primary-light-3);
  --el-link-disabled-text-color: var(--el-color-primary-light-5);
  cursor: pointer;
  padding: 0;
  font-size: var(--el-link-font-size);
  font-weight: var(--el-link-font-weight);
  color: var(--el-link-text-color);
  text-decoration: none;
}

.el-form--inline .el-form-item {
  margin-right: 0;
  margin-left: 32px;
}

.search-wrapper {
  position: relative;
  height: 50px;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 0 rgba(101, 109, 119, .16);
  box-sizing: border-box;

  .p-left {
    position: absolute;
    height: 100%;
  }

  .p-right {
    position: absolute;
    right: 14px;
    top: 0px;
    height: 50px;
    display: flex;
    align-items: center;
  }
}

.filter-form {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;

  .el-form-item {
    margin-bottom: 0;
  }
}

.el-pagination {
  margin-top: 20px;
  justify-content: right;
}

.el-select {
  width: 100%;
}

.card-wrapper {
  width: 100%;
  height: calc(100% - 400px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;

  .el-card {
    cursor: pointer;
    margin: 12px 12px 0 0;

    .card-header {
      text-align: left;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .d-flex {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .d-child {
        flex-grow: 1;
        height: 40px;
      }
    }
  }

  .el-card.border1:hover {
    border: solid 1px #409EFF;
  }

  .el-card.border2:hover {
    border: solid 1px #909399;
  }

  .el-card.border3:hover {
    border: solid 1px #F56C6C;
  }

  .el-card.border4:hover {
    border: solid 1px #F56C6C;
  }

  .el-card.border5:hover {
    border: solid 1px #E6A23C;
  }

  .el-card.border6:hover {
    border: solid 1px #409EFF;
  }

  .el-card.border1:hover {
    border: solid 1px #409EFF;
  }

}

.el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}
</style>
