import axios from '@/utils/request.js'

const lifetimeVisitApi = {
    getLifetimeVisits(projectId, screenNo) {
        return axios.get(`/LifetimeVisit/GetLifetimeVisits?projectId=${projectId}&screenNo=${screenNo}`);
    },
    addOrUpdate(data) {
        return axios.post('/LifetimeVisit/AddOrUpdate', data);
    }
}

export default lifetimeVisitApi