<template>
    <el-dialog title="用户配置" width="30%" draggable v-model="showDialog" :before-close="handleClose">

        <el-form :model="user" label-width="120px">
            <el-form-item label="登录用户名">
                <el-input v-model="user.loginName"  disabled/>
            </el-form-item>

            <el-form-item label="姓名">
                <el-input v-model="user.name" disabled />
            </el-form-item>

            <el-form-item label="性别">
                <el-radio-group v-model="user.sex" disabled>
                    <el-radio :label="0">男</el-radio>
                    <el-radio :label="1">女</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="电话">
                <el-input v-model="user.phoneNumber" />
            </el-form-item>

            <el-form-item label="邮箱">
                <el-input v-model="user.email" />
            </el-form-item>

        </el-form>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="close()">取消</el-button>
                <el-button type="primary" @click="modifyUser()">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean
        }
    },
    data() {
        return {
            user: {
                loginName: '',
                name: '',
                sex: -1,
                phoneNumber: '',
                email: '',
                id: -1,
                roleId: '',
                roleName: ''
            }
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit('closeUserInfo', val)
            }
        },
        currentUser() {
            return this.$store.state.currentUser;
        }
    },
    methods: {
        handleClose() {
            this.user = this.currentUser;
            this.$emit('closeUserInfo', false);

        },
        close() {
            this.$emit('closeUserInfo', false);
            this.user = this.currentUser;
        },
        modifyUser() {
        }
    },
    mounted(){
        this.user = this.currentUser;
    },
    watch:{
        visible(){
            this.user = this.currentUser;
        }
    }

}
</script>
