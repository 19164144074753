import axios from '@/utils/request.js'

const appUpdateApi = {
    getAll() {
        return axios.get(`/AppUpdate/GetAll`);
    },
    add(data) {
        return axios.post("/AppUpdate/Add", data);
    },
    del(id){
        return axios.post(`/AppUpdate/Del?id=${id}`);
    },
    getRemindByUser(id){
        return axios.get(`/AppUpdate/GetRemindByUser?id=${id}`);
    }
}

export default appUpdateApi