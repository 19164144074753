<template>
  <div class="container overflow-hidden">
    <div class="d-flex header-wrapper">
      <div class="logo-wrapper">
        <div>
          <h3><router-link to="/">临床研究患者管理平台</router-link></h3>
        </div>
      </div>

      <div class="user-wrapper d-flex">
        <div class="user-name">
          <el-text>欢迎您，{{ currentUser.name }}</el-text>
        </div>
        <div class="avatar-wrapper">
          <el-dropdown :hide-on-click="false">
            <el-avatar :size="40" :src="circleUrl" class="el-dropdown-link"><arrow-down /></el-avatar>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="userInfoVisible = true"><el-icon>
                    <User />
                  </el-icon>用户信息</el-dropdown-item>
                <el-dropdown-item v-if="currentUser.roleId == '001'"><el-icon>
                    <Setting />
                  </el-icon><router-link to="/system/user">系统设置</router-link></el-dropdown-item>
                <el-dropdown-item @click="changePwdVisible = true"><el-icon>
                    <Unlock />
                  </el-icon>修改密码</el-dropdown-item>
                <el-dropdown-item @click="logout"><el-icon>
                    <SwitchButton />
                  </el-icon>退出系统</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <user-info :visible="userInfoVisible" @closeUserInfo="closeUserInfo" />
      <ChangePwd :show="changePwdVisible" @closePwd="closePwd" />
    </div>
  </div>
</template>

<script>
import UserInfo from "./UserInfo.vue";
import ChangePwd from '@/components/Dialogs/ChangePassword.vue'

export default {
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      userInfoVisible: false,
      changePwdVisible: false,
    };
  },
  components: {
    UserInfo,
    ChangePwd
  },
  methods: {
    logout() {
      this.$store.commit("changeAuthentication", false);
      this.$router.push("/login");
      localStorage.removeItem("token");
      localStorage.removeItem('uid');
      localStorage.removeItem('pid');
      localStorage.removeItem('url');
      localStorage.removeItem('ids');
    },
    closeUserInfo(val) {
      this.userInfoVisible = val;
    },
    closePwd(val){
      this.changePwdVisible = val;
    }
  },
  computed: {
    isAuthentication() {
      return this.$store.state.isAuthentication;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    currentProject() {
      return this.$store.state.currentProject;
    }
  },
};
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
  flex-wrap: nowrap;
}

// header区域样式
.header-wrapper {
  width: 100%;

  h3 {
    margin: 0;
    line-height: var(--el-header-height);

    .router-link-active {
      color: var(--el-menu-hover-text-color);

    }
  }

  //logo区域样式
  .logo-wrapper {
    margin: 0 40px;
  }

  .menu-wrapper {
    width: 70%;
  }

  .user-wrapper {
    width: 260px;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;

    .user-name {
      margin-right: 20px;
    }
  }
}

.el-menu--horizontal {
  height: var(--el-header-height);
  border-bottom: none;
}

.el-header {
  .el-row {
    padding: 0 40px;
    margin-left: auto;

    .avatar-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      color: #fff;
      line-height: var(--el-header-height);
    }
  }
}

.example-showcase .el-dropdown+.el-dropdown {
  margin-left: 15px;
}

.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}
</style>